body {
  background-color: bisque;
}

.container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: 40px 40px 40px 40px 40px 40px;
  gap: 2px;
  background-color: gray;
  width: 30%;
  text-align: center;
  margin: 40px 20px 40px auto;
}

.container button {
  padding: 8px 1px;
  border: none;
}

.zero {
  grid-column: 1/3;
}

.display {
  grid-column: 1/5;
  text-align: end;
  background-color: #858693;
  color: #fff;
}

.white {
  background-color: #e0e0e0;
}

.yellow {
  background-color: #f4913e;
}

.quote {
  padding: 10px;
  text-align: center;
  font-weight: 900;
}

.navbar {
  display: flex;
  justify-content: space-between;
}

h1 {
  padding: 0 20px;
  font-size: 42px;
}

.navbar ul {
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
  margin-right: 20px;
}

.navbar a {
  padding: 5px 10px;
}

.navbar a:active {
  color: blue;
}

.navbar a:hover {
  color: red;
}

.navbar li:nth-child(2) a {
  border-left: 2px solid black;
  border-right: 2px solid black;
}
